
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
  --main-color: #4f55ff;
  --text-color: #292929;
}

html, body {
  scroll-behavior: smooth;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

button {
  background: transparent;
  border: none;
}

.gifBackground {
  height: 500px;
  background-image: url('../public/images/pink-petals.gif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center
}

.Navbar {
  position: fixed;
  width: 100%;
  top: 0;
  padding: .5rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-bottom: .5px solid #bcbcbc;
  background: #fff;
  opacity: 0.8;
  z-index: 1000;
}

.Navbar ul {
  display: inline-flex;
  list-style: none;
}

.Navbar a {
  text-decoration: none;
  color: var(--main-color);
  margin: 0 1rem;
  font-weight: bold;
  padding: 0.5rem;
}

.Hero .line {
  margin-top: 10%;
  font-size: 6rem;
  text-align: center;
  font-weight: bold;
  line-height: 7rem;
}

.Hero .words {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.HeroContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: #f0f0f0;
}

.image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.image-container img {
  position: absolute;
  z-index: -100;
  object-fit: cover;
  animation: randomAnimation 10s infinite;
  opacity: 0;
}

.AbsoluteRight {
  right: 0;
}

.image-container img:nth-child(1) {
  animation-delay: 8s;
}

.image-container img:nth-child(2) {
  animation-delay: 12s;
}

.image-container img:nth-child(3) {
  animation-delay: 16s;
}

.image-container img:nth-child(4) {
  animation-delay: 20s;
}

.HeroDescription {
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 2rem;
  margin-top: 2rem;
}

.HeroDescription h4 {
  background: transparent;
  color: var(--main-color);
  font-size: 2rem;
  padding: .5rem 2rem;
  border-radius: 10px;
  line-height: 3rem;
}

.sectionHeading {
  text-align: center;
  align-items: center;
}

.sectionHeading h1 {
  font-size: 3rem;
  padding: 1rem;
  color: var(--main-color);
  line-height: 3.8rem;
}

.sectionHeading p {
  padding: 0 2rem;
  font-size: 1.5rem;
  color: #000000;
  font-weight: bold;
  line-height: 2.2rem;
}

.imageCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.joinCardContainer,
.featureCardContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5rem;
  flex-wrap: wrap;
}

.joinCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  border-radius: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  width: 400px;
  overflow: hidden;
  margin-bottom: 2rem;
}

.joinCard img {
  opacity: 0.5;
  width: 100%;
}

.joinCard h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f0f0f0;
  font-weight: bold;
  z-index: 30;
  font-size: 2rem;
  text-align: center;
  margin: 0;
  line-height: 3rem;
}

.joinCard p {
  color: #000000;
  padding: 2rem;
  font-weight: bold;
}

.featureCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  overflow: hidden;
  margin-bottom: 2rem;
}

.featureCardImage {
  width: 200px;
  height: 200px;
}

.featureCard img {
  width: 100%;
}

.featureCard h3 {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  line-height: 2.2rem;
}

.featureCard p {
  padding: 0 2rem;
  font-weight: bold;
  text-align: center;
  margin: 0;
  margin-bottom: 2rem;
}

.photoTag {
  text-align: center;
  padding: 1rem;
  padding-bottom: 2rem;
  color: var(--main-color);
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.Container2 {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 2rem 5rem;
  flex-wrap: wrap;
}

.Container2 .box1 {
  flex-basis: 50%;
  align-items: center;
  text-align: center;
}

.Container2 .box1 img {
  width: 80%;
}

.Container2 .box2 {
  flex-basis: 50%;
  font-size: 1.3rem;
  align-items: center;
  margin: auto;
}

.Container2 .box2 h3 {
  color: var(--main-color);
  font-size: 2rem;
}

.Container2 img {
  width: 24rem;
}

.Container2 .box2 ul li {
  list-style-type: square;
  margin: 1rem 0;
}

.myButton {
  text-align: center;
  padding: 2rem;
}

.myButton button {
  background-color: var(--main-color);
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 10px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 2rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.smallBtn {
  text-align: left;
}

.smallBtn button {
  background-color: var(--main-color);
  color: #fff;
  padding: .4rem .6rem;
  border-radius: 5px;
  font-size: .9rem;
  border: none;
  outline: none;
  cursor: pointer;
}

.footer {
  display: flex;
  align-items: center;
  padding: 5rem;
  flex-wrap: wrap;
}

.footer .leftFooter form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.footer form input {
  padding: .5rem 1rem;
  width: 300px;
  border: 2px solid var(--main-color);
  font-size: 1rem;
  border-radius: 5px;
  margin: .5rem 0;
  font-weight: bold;
}

.footerMidline {
  border-left: 2px solid var(--main-color);
  height: 160px;
  margin-left: 20px;
  margin-right: 20px;
}

.footer .rightFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.footer .rightFooter ul {
  padding-left: 0;
}

.footer .rightFooter ul li {
  list-style: none;
  margin: .5rem 0;
}

.footer a {
  color: var(--main-color);
  text-decoration: none;
  font-weight: bold;
}

.footer .rightFooter p {
  margin: .3rem 0;
}

.footer form input[type="submit"] {
  background: var(--main-color);
  color: #fff;
  width: 100px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
}

.copyRight {
  text-align: center;
  padding: 1rem;
}


/* MODAL */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
}

.modalBox {
  position: relative;
  background-color: #fff;
  padding: 5rem;
  border-radius: 1rem;
  height: fit-content;
  width: 50%;
  min-width: 30rem;
  border: 2px solid var(--main-color);
}

.modelTitle {
  text-align: center;
}

.modelTitle p {
  font-weight: bold;
}

.modalCloseButton {
  font-size: 16px;
  cursor: pointer;
}

.modalForm form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalForm form input[type="submit"] {
  background: var(--main-color);
  color: #fff;
  width: 100px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
}

.modalForm form input,
.modalForm form textarea {
  padding: .5rem 1rem;
  width: 300px;
  min-width: 200px;
  border: 2px solid var(--main-color);
  font-size: 1rem;
  border-radius: 5px;
  margin: .5rem 0;
  font-weight: bold;
}

.selectOptions{
  display: flex;
  flex-direction: left;
  justify-content: left;
  flex-wrap: wrap;
  width: 70%;
  min-width: 400px;
}
.selectOptions .selectOption{
  align-items: left;
  margin-left: 3rem;
  font-weight: bold;
}

.normal-text{
  font-weight: bold;
  color: #5b5b5b;
}

.word1{
  padding: .5rem 1rem;
  border-left: .1rem solid var(--main-color);
  border-top: .4rem solid var(--main-color);
}
.word2{
  padding: .5rem 1rem;
  border-right: .4rem solid var(--main-color);
  border-bottom: .1rem solid var(--main-color);
}

.word3{
  padding: .5rem 1rem;
  border: .4rem solid var(--main-color);
  border-top: .1rem solid var(--main-color);
  border-right: .1rem solid var(--main-color);
}

@keyframes randomAnimation {
  0% {
    opacity: 0;
    transform: scale(1) rotate(0deg) translateX(0) translateY(0);
  }

  25% {
    opacity: 1;
    transform: scale(1.2) rotate(10deg) translateX(20px) translateY(-10px);
  }

  50% {
    opacity: 0;
    transform: scale(1.1) rotate(-10deg) translateX(-20px) translateY(10px);
  }

  75% {
    opacity: 0.5;
    transform: scale(1) rotate(0deg) translateX(0) translateY(0);
  }

  100% {
    opacity: 0;
    transform: scale(1) rotate(0deg) translateX(0) translateY(0);
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 16px;
    /* Reducing to 14px will scale down text across the page */
  }
  .Hero .line {
    margin-top: 20%;
  }

  .Container2 {
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  html {
    font-size: 12px;
  }
  .Hero .line {
    margin-top: 24%;
  }
  .Navbar ul {
    padding: 0;
  }
  .joinCardContainer{
    padding: 4rem;
  }
  .modalForm form input, .modalForm form textarea{
    width: auto;
  }
  .modalOverlay{
    left: -15px;
  }
  .modalBox{
    padding: 1rem;
  }
  .modalOverlay{
    width: auto;
  }

  .Container2 {
    flex-direction: column;
  }
  .footer{
    padding: 2rem;
  }
}